<template>
  <div class="w-full p-8">
    <section class="w-full">
      <ScrollObserver class="relative mx-auto flex justify-center items-center max-w-3xl" @update="sectionUpdate">
        <ScrollTriggerGroup class="max-w-full sm:text-4xl text-3xl">
          <ScrollTrigger v-slot="{ isActive }" class="relative my-[10vh] flex-shrink-0">
            <span class="absolute inset-0 text-white transition" :class="{ 'opacity-0': !isActive }">
              Crypto is for everyone, but it's plagued by
              <span class="bg-gradient-to-r from-red-300 to-rose-400 bg-clip-text text-transparent transition">scams and rug pulls</span>
              —that needs to end
            </span>

            <span class="relative text-white/20 transition" :class="{ 'opacity-0': isActive }">Crypto is for everyone, but it's plagued by scams and rug pulls—that needs to end.</span>
          </ScrollTrigger>

          <ScrollTrigger v-slot="{ isActive }" class="relative my-[10vh] flex-shrink-0">
            <span class="absolute inset-0 text-white transition" :class="{ 'opacity-0': !isActive }">
              Oxbull is here to
              <span class="bg-gradient-to-r from-yellow-300 to-amber-400 bg-clip-text text-transparent transition">revolutionize this space</span>
              . As a launchpad and incubator
            </span>

            <span class="relative text-white/20 transition" :class="{ 'opacity-0': isActive }">Oxbull is here to revolutionize this space. As a launchpad and incubator.</span>
          </ScrollTrigger>

          <ScrollTrigger v-slot="{ isActive }" class="relative my-[10vh] flex-shrink-0">
            <span class="absolute inset-0 text-white transition" :class="{ 'opacity-0': !isActive }">
              We connect top-notch blockchain projects with investors, emphasizing
              <span class="bg-gradient-to-r from-lime-300 to-green-400 bg-clip-text text-transparent transition">quality, credibility, and relentless execution</span>
            </span>

            <span class="relative text-white/20 transition" :class="{ 'opacity-0': isActive }">We connect top-notch blockchain projects with investors, emphasizing quality, credibility, and relentless execution.</span>
          </ScrollTrigger>
        </ScrollTriggerGroup>
      </ScrollObserver>
    </section>

    <section class="w-full">
      <!-- <div class="w-full text-center">
        <h6 class="text-white text-xl">Builders at Heart, Future in Sight</h6>
      </div> -->

      <div class="flex justify-center items-center relative w-full">
        <BracketTitle class="w-full hidden sm:block">
          <h2 class="z-10 text-turquoise-500 font-bold sm:text-[5vw] text-xl w-full leading-[5rem]">
            Good day, bad day,<br>We BUIDL.
          </h2>
        </BracketTitle>

        <div class="text-center text-3xl font-brand font-bold z-20 text-turquoise-500 sm:text-[5vw] sm:space-y-12 sm:hidden mb-4">
          <h2>Good Day, Bad Day</h2>
          <h2>We BUIDL.</h2>
        </div>

        <img class="w-[2000px] h-[2000px] object-contain opacity-20 absolute" src="/blur.png" alt="Oxbull primary colored blur effect">

        <!-- <div class="h-[50vw] w-[50vw] absolute bg-turquoise-500 blur-[200px] rounded-full z-0 opacity-30"></div> -->
      </div>

      <div class="w-full flex sm:flex-row flex-col justify-center items-center z-20 relative">
        <div class="sm:w-12 w-16 sm:h-12 h-16 bg-white rounded-full flex-shrink-0 mb-8 sm:mb-0 overflow-hidden">
          <img class="w-full h-full object-cover" src="/ushi.jpg" alt="">
        </div>
        <p class="sm:ml-4 text-white sm:text-xl font-brand font-bold">- OxUshi, Founder of Oxbull.tech</p>
      </div>

      <div class="w-full flex justify-center items-center mt-24 pb-12 sm:px-8">
        <p class="sm:max-w-screen-lg text-justify sm:leading-10 leading-loose sm:text-xl text-base font-light text-zinc-400">
          Since February 2021, Oxbull has been helping startups by providing funding and support to get their projects off the ground efficiently. As a launchpad and incubator, it connects promising blockchain projects with investors, offering chances to invest with big names like Binance Labs and Animoca Brands. Oxbull stands for
          <span class="bg-gradient-to-r from-lime-300 to-green-400 bg-clip-text text-transparent transition">quality, credibility, and relentless execution</span>
          , ensuring that projects launched with it meet these standards.
        </p>
      </div>
    </section>
  </div>
</template>

<script setup>
  import { debounce } from "lodash";

  const missions = [
    "Crypto is for everyone, but it's plagued by scams and rug pulls—that needs to end.",
    "Oxbull is here to revolutionize this space. As a launchpad and incubator.",
    "We connect top-notch blockchain projects with investors, emphasizing quality, credibility, and relentless execution."
  ]
  
  const sectionUpdate = debounce((e) => {
    e > -1 && dataLayer.push({
      event: "scroll_to_mission",
      page: window.location.pathname,
      mission_description: missions[ e ]
    })
  }, 150);

</script>

<style>

</style>